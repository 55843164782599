import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/about'
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/LeaderboardView.vue')
  },
  {
    path: '/pendingrun',
    name: 'pendingrun',
    component: () => import(/* webpackChunkName: "about" */ '../views/PendingrunView.vue')
  },
  {
    path: '/submitrun',
    name: 'submitrun',
    component: () => import(/* webpackChunkName: "about" */ '../views/SubmitRunView.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "about" */ '../views/MapView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
