<template>
  <div id="app">
    <div>
      <el-menu router default-active="/about" mode="horizontal">
        <el-menu-item index="/about">关于</el-menu-item>
        <el-menu-item index="/map">地图下载</el-menu-item>
        <el-menu-item index="/submitrun">提交速通</el-menu-item>
        <el-menu-item index="/leaderboard">排行榜</el-menu-item>
        <el-menu-item index="/pendingrun">待审核</el-menu-item>
      </el-menu>
    </div>
    <div class="view">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
